import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { graphql } from 'gatsby'

import Layout from 'components/layout'
import MetaTags from 'components/MetaTags'
import Section from 'components/Section'
import Wrapper from 'components/Wrapper'
import Header from 'components/Header'
import GridRow from 'components/grid/GridRow'
import GridItem from 'components/grid/GridItem'

const GiftCard = ({ data }) => {
  const intl = useIntl()

  const {
    headerImage: {
      childImageSharp: { fluid },
    },
  } = data

  return (
    <Layout>
      <MetaTags title='Merci | Hotel Griffintown' noindex />
      <Header eyebrow='Merci' heading='Certificat cadeau' fluid={fluid} />
      <Section>
        <Wrapper size='narrow'>
          <GridRow>
            <GridItem xs='1-1' lg='3-4'>
              <p>
                Nous avons bien reçu votre commande ce carte-cadeau et nous la
                traiterons très rapidement.
              </p>

              <p>Ne manquez pas de visiter nos offres en cours.</p>
            </GridItem>
          </GridRow>
        </Wrapper>
      </Section>
    </Layout>
  )
}

export default GiftCard

export const query = graphql`
  query GiftCardThanksPage {
    headerImage: file(
      name: { eq: "GriffintownHotel-CertificatCadeau-ImgSite" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2560, quality: 100, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
